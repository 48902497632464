import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../store/actions";
// import { TextField } from '@mui/material';
import { TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import { DateRange } from "react-date-range";
import Moment from "moment";
import PhoneInput from "react-phone-input-2";
import SubQuestions from "./subQuestions";
import "react-phone-input-2/lib/style.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { isArray } from "util";
import moment from "moment";
import { checkServerIdentity } from "tls";
import { parseValue } from "graphql";
import { notDeepStrictEqual } from "assert";
import MonthRangePicker from "../components/MonthRangePicker";
import { setDesitnationThroughParams, setSummary, updateValues } from "../store/actions";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { log } from "console";
const NestedQue = (props: any) => {
  const {
    node,
    index,
    setFlagQue,
    moveToBack,
    ageGroup,
    adultsCount,
    setAdultsCount,
    childrensCount,
    setChildrensCount,
    setCurrentStep,
    Summary,
    submitData,
    selectionRange,
    setSelectionRange,
    setNextDisplay,
    setNextNodeID,
    QuestionsLength,
    Questions,
    dateFlexFlag,
    setDateFlexFlag,
    showSummery,
    setShowSummery,
    category,
    setFlexibleDate,
    flexibleDate,
    setRooms,
    rooms,
    airSelectedStatus,
    setAirSelectedStatus,
    roomArraangement,
    setRoomArraangement,
    setReadyToShowSummery,
    submitAPIFlag,
    totalDaysSelected,
    setTotalDaysSelected,
    toggleHover,
    setToggleHover,
    setProgressBar,
    progressBar,
    monthInWords,
    currentMonth,
    date,
  } = props;
  const dispatch = useDispatch();
  const [validation, setValidation] = useState("");
  const summ=useSelector((state:any)=>state.Question.Summary)
   const Location = useSelector((state:any)=>state.Question.ParamLocation)
   let arr:any[]
  const [month, setMonth] = useState("");
  console.log("hii", node);
  var today = new Date();
  const maxDate = new Date(today.setFullYear(today.getFullYear() + 100));
  
  const [monthRange, setmonthRange] = useState({
    from : new Date(),
    to : new Date()
  })

  useEffect(() => {
    if(Summary.desiredCuriseMonth.value){
      const date = Summary.desiredCuriseMonth.value.split("-")
      const xm = date[0].split(",")[0]
      const xy= parseInt(date[0].split(",")[1]) 
      const ym = date[1].split(",")[0]
      const yy= parseInt(date[1].split(",")[1])
      const from = new Date()
      from.setMonth(monthInWords.indexOf(xm))
      from.setFullYear(xy)
      const to = new Date()
      to.setMonth(monthInWords.indexOf(ym))
      to.setFullYear(yy)
      setmonthRange({
        from,
        to
      })
 
    }
  
  }, [Summary.desiredCuriseMonth.value])
  
  console.log("mnb",monthRange);
  


  const handleAnsSelection = async (
    key: any,
    node: any,
    parent: any,
    naming = ""
  ) => {
    console.log(
      "updateValues",
      node + "789" + key + "123" + parent + "456" + naming
    );
    setValidation("");

    if (
      node.answerType === "MultipleChoice" ||
      node.answerType === "MultipleChoiceTextInput" ||
      node.answerType === "MultipleChoiceCheckBox"
    ) {
      await dispatch(Actions.updateSelections(key, node, parent));
    } else if (node.answerType === "SingleChoice") {
      await dispatch(Actions.updateSingleSelections(key, node, parent));
    } else if (
      node.answerType === "TextInput" ||
      node.answerType === "FirstNameLastName" ||
      node.answerType === "Toggler" ||
      node.answerType === "TextAreaInput" ||
      node.answerType === "Email" ||
      node.answerType === "Phone" ||
      node.answerType === "Passengers" ||
      node.answerType === "DateRange"
    ) {
      await dispatch(Actions.updateValues(key, node, parent, naming));
    }
    if (node.key === "bookingHelp") {
      //0x25892b4e1
      const ans = node.selectedAns.filter((data: any) => data === "Air");
      if (ans.length >= 1) {
        await setAirSelectedStatus(true);
        console.log("updateValuesYYYYY", node);
        await dispatch(Actions.updateAir("departureAirport", 0));
      } else {
        await setAirSelectedStatus(false);
        console.log("updateValuesNNNNN", node);
        await dispatch(Actions.updateAir("departureAirport", 1));
      }
    }
  };
  console.log("questionsquestions", Questions);
  const handleFlexibleDate = async (key: number, node: any) => {
    await setFlexibleDate(
      flexibleDate === 0 && key === -1
        ? 0
        : flexibleDate === 5 && key === 1
        ? 5
        : flexibleDate + key
    );
    // handleAnsSelection(adultsCount + childrensCount, node, {});
  };
  console.log("aroooo", flexibleDate);

  const handleRooms = async (key: number, node: any) => {
    setRooms(rooms === 1 && key === -1 ? 1 : rooms + key);
    await handleAnsSelection(rooms, node, {});
  };
  const handleToggleHover = async (flag: boolean) => {
    setToggleHover(flag);
  };
  const setAnsSelection = async (node: any) => {
    console.log("setAnsSelection", node);
    if (node.key === "bookingHelp") {
      //0x25892b4e1
      const ans = node.selectedAns.filter((data: any) => data === "Air");
      if (ans.length >= 1) {
        await setAirSelectedStatus(true);
        await dispatch(Actions.updateAir("departureAirport", 1));
      } else {
        await setAirSelectedStatus(false);
        await dispatch(Actions.updateAir("departureAirport", 0));
      }
    }
    if (node.key === "cruiseDestinations"&&node.answerChoices.filter((n:any)=>{return n.value.toLowerCase().includes(Location.toLowerCase())}).length===0 && !node.selectedAns.includes("Any Destination")) {
     
    node.selectedAns=  node.selectedAns.slice(1,node.selectedAns.length)
      console.log('my',node.selectedAns)
  
    // handleAnsSelection(node.selectedAns,node,{})
    }
    if (node.answerType === "Passengers") {
      node.selectedAns = adultsCount + childrensCount;
      var len = 0;
      console.log("setAnsSelection", roomArraangement);
      if (childrensCount > 0) {
        roomArraangement.map((age: any) => {
          if (age.childOneAge >= 0 && age.childrens > 0) {
            len++;
            console.log("childssssage1", childrensCount, len, age);
          }
          if (age.childTwoAge >= 0 && age.childrens > 0) {
            len++;
            console.log("childssssage2", childrensCount, len, age);
          }
          if (age.childThreeAge >= 0 && age.childrens > 0) {
            len++;
            console.log("childssssage3", childrensCount, len, age);
          }
          if (age.childFourAge >= 0 && age.childrens > 0) {
            len++;
            console.log("childssssage4", childrensCount, len, age);
          }
          console.log("childssssage", childrensCount, len, age);

          // if (age.childrens > 1) {
          //     if (age.childOneAge === -1) {
          //         setValidation("Please enter correct child age");
          //     }
          // }
          // if (age.childrens > 2) {
          //     if (age.childOneAge === -1 || age.childTwoAge === -1) {
          //         setValidation("Please enter correct child age");
          //     }
          // }
          // if (age.childrens > 3) {
          //     if () {
          //         setValidation("Please enter correct child age");
          //     }
          // }
        });
        if (childrensCount !== len) {
          setValidation("Please enter correct child age");
        }
        if (childrensCount === len) {
          dispatch(
            Actions.updateValues(adultsCount + childrensCount, node, {})
          );
          updateData(node);
          setProgressBar(progressBar + 7.142857143);
        }
      } else {
        dispatch(Actions.updateValues(adultsCount + childrensCount, node, {}));
        updateData(node);
        setProgressBar(progressBar + 7.142857143);
      }
    } else if (node?.answerType === "FirstNameLastName") {
      if (!node.selectedAns1 || !node.selectedAns2) {
        setValidation("Please enter valid first name and last name");
      } else if (node.selectedAns1 && node.selectedAns1 === "") {
        setValidation("Please enter valid first name");
      } else if (node.selectedAns2 && node.selectedAns2 === "") {
        setValidation("Please enter valid last name");
      } else {
        setProgressBar(progressBar + 7.142857143);
        updateData(node);
      }
    } else if (
      !node.skippable &&
      (!node.selectedAns || node?.selectedAns === null)
    ) {
      setValidation("Please enter / select valid data");
    } else if (
      !node.skippable &&
      Array.isArray(node?.selectedAns) &&
      node?.selectedAns.length === 0
    ) {
      setValidation("Please select valid data");
    } else if (
      !node.skippable &&
      node?.selectedAns.length <= 1 &&
      (node.answerType === "TextInput" ||
        node.answerType === "FirstNameLastName" ||
        node.answerType === "TextAreaInput")
    ) {
      setValidation("Please enter / select valid data");
    } else if (node?.answerType === "Email") {
      //|| )
      if (
        !RegExp(/[a-zA-Z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,15}/g).test(
          node?.selectedAns
        )
      ) {
        setValidation("Invalid email ID");
      }
     

      else if (
        !node.subQueList[0].selectedAns ||
        node.subQueList[0].selectedAns.length <= 10
      ) {
        setValidation("Invalid phone number");
      }
       else {
        console.log(
          "setAnsSelection562",
          !node.subQueList[0].selectedAns ||
            node.subQueList[0].selectedAns <= 10,
          node.subQueList[0].selectedAns.length
        );
        updateData(node);
        setProgressBar(progressBar + 7.142857143);
      }
    } else {
      console.log("setAnsSelection", node);
      updateData(node);
      setProgressBar(progressBar + 7.142857143);
    }
  };
  const updateData = (node: any) => {
    console.log("1234567890", node);
    setValidation("");
    // if(node.)
    if (node.nextQuestion) {
      if (node.nextQuestion.id) {
        if (QuestionsLength === index) {
          setNextDisplay(node.nextQuestion.id);
          setNextNodeID(node.nextQuestion.id);
        } else {
          console.log("1234567890", node);
          if (Questions[index].hide === 0) {
            setNextDisplay(Questions[index].nextQuestion.id);
          } else {
            setNextDisplay(node.nextQuestion.id);
          }
        }
        setFlagQue(true);
      }
      setShowSummery(false);
      dispatch(Actions.updateSummary());
    } else {
      dispatch(Actions.updateSummary());
      setCurrentStep(category.length - 1);
      setReadyToShowSummery(true);
      setShowSummery(true);
    }
  };

  const handleSelect = (ranges: any) => {
    // totalDaysSelected, setTotalDaysSelected
    let date1: Date = new Date(ranges.selection.startDate);
    let date2: Date = new Date(ranges.selection.endDate);

    let timeInMilisec: number = date2.getTime() - date1.getTime();
    let daysBetweenDates: number = Math.ceil(
      timeInMilisec / (1000 * 60 * 60 * 24)
    );
    setTotalDaysSelected(daysBetweenDates + 1);
    setSelectionRange({
      startDate: new Date(ranges.selection.startDate),
      endDate: new Date(ranges.selection.endDate),
      key: "selection",
    });
  
    dispatch(
          Actions.updateValues(
            {
              startDate: new Date(ranges.selection.startDate).toDateString(),
              endDate: new Date(ranges.selection.endDate).toDateString(),
            },
            node,
            {}
          )
        );
  };
  const addNewRoom = async (node: any) => {
    var data: any = [...roomArraangement];
    let i = roomArraangement.length;
    data.push({
      room_no: i,
      adults: 1,
      childrens: 0,
      childOneAge: -1,
      childTwoAge: -1,
      childThreeAge: -1,
      childFourAge: -1,
    });
    await setRoomArraangement(data);
    handleChildrens(1, node, data);
    handleAdults(1, node, data);
  };
  const deleteRoom = async (node: any, master: any) => {
    var data: any = [...roomArraangement];
    console.log("roomArraangement", roomArraangement, node);
    data = data.filter((n: any) => n.room_no !== node.room_no);
    console.log("roomArraangement data", data);
    await setRoomArraangement(data);
    handleChildrens(1, master, data);
    handleAdults(1, master, data);
  };
  const handleAdults = async (key: number, node: any, data = []) => {
    let count = 0;
    data.map((node: any) => {
      count += node.adults;
    });
    setAdultsCount(count);
    handleAnsSelection(count + childrensCount, node, {});
  };
  const handleChildrens = async (key: number, node: any, data = []) => {
    let count = 0;
    data.map((node: any) => {
      count += node.childrens;
    });
    await setChildrensCount(count);
    handleAnsSelection(adultsCount + count, node, {});
  };
  const updateCount = (flag: number, i: number, key: string, node: any) => {
    console.log("roomArraangementroomArraangement", roomArraangement);

    var data: any = [...roomArraangement];
    if (
      key === "adult" &&
      ((flag === 1 &&
        (node.type === "Cruise"
          ? data[i].adults + data[i].childrens < 8
          : data[i].adults + data[i].childrens < 5)) ||
        (flag === -1 && data[i].adults > 1))
    ) {
      data[i].adults += flag;
      handleAdults(flag, node, data);
    } else if (
      key === "children" &&
      ((flag === 1 &&
        (node.type === "Cruise"
          ? data[i].childrens < 4 && data[i].adults + data[i].childrens < 8
          : data[i].childrens < 3 && data[i].adults + data[i].childrens < 5)) ||
        (flag === -1 && data[i].childrens > 0))
    ) {
      data[i].childrens += flag;
      data[i].childrens = data[i].childrens <= 0 ? 0 : data[i].childrens;
      if (data[i].childrens === 0 && flag === -1) {
        data[i].childOneAge = Number(-1);
      } else if (data[i].childrens === 1 && flag === -1) {
        data[i].childTwoAge = Number(-1);
      } else if (data[i].childrens === 2 && flag === -1) {
        data[i].childThreeAge = Number(-1);
      } else if (data[i].childrens === 3 && flag === -1) {
        data[i].childFourAge = Number(-1);
      }
      handleChildrens(flag, node, data);
    }
    setRoomArraangement(data);
  };
  const handleChildrenChange = (value: any, node: any, key: any) => {
    var data = [...roomArraangement];
    console.log(
      "roomArraangementroomArraangement1",
      value,
      node,
      data.findIndex((n: any) => n.room_no === node.room_no),
      key
    );
    let index = data.findIndex((n: any) => n.room_no === node.room_no);
    if (index !== -1) {
      if (key === 1) {
        data[index].childOneAge = Number(value);
      } else if (key === 2) {
        data[index].childTwoAge = Number(value);
      } else if (key === 3) {
        data[index].childThreeAge = Number(value);
      } else {
        data[index].childFourAge = Number(value);
      }
    }
    console.log("roomArraangementroomArraangement2", data, value);
    setRoomArraangement(data);
  };
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 4);
  // useEffect(() => {
  //     console.log("SummarySummary", Summary);
  // }, [Summary])
  const moveToStep = (key: any) => {
    setShowSummery(false);
    var indx = Questions.findIndex((node: any) => {
      return node.key === key;
    });
    if (indx > -1) {
      setNextDisplay(Questions[indx].id);
      setNextNodeID(Questions[indx].id);
      setProgressBar(progressBar - 7.142857143 * (Questions.length - indx));
    }
  };

  function sort(array: any[], from: number, to: number) {
    array.splice(to, 0, array.splice(from, 1)[0]);
  }

  useEffect(() => {
    const data = node.answerChoices.filter(
      (smallNode: any) => node.selectedAns[0] === smallNode.value
    );

    console.log("DATAAAAAAAAA", node.answerChoices, node.selectedAns[0], data);
  }, []);

  return (
    <>
      <div>
        <div className="queDisplay">
          {index === Questions.length && node.loadSubSet
            ? node?.subQuestions.map((subNode: any, key: any) => {
                return <SubQuestions key={key} node={node} subNode={subNode} />;
              })
            : null}
          <div className="MainQueSet">
            <div
              className={
                node?.validResponse
                  ? "MainQuestion animationFadeIn2"
                  : "MainQuestion animationFadeIn1"
              }
            >
              {showSummery ? (
                <div>
                  {console.log("roomArraangement", roomArraangement)}
                  <div className="tripLocation">
                    {Summary.destination.value
                      ? "Your trip to " + Summary.destination.value
                      : ""}
                  </div>
                  <div className="h4update">
                    <h4>Submit your choices to receive the itinerary</h4>
                    <br />
                  </div>
                  <div className="summery">
                    <div className="perfect">Perfect!</div>
                    <div>
                      <span
                        className="sumvalue"
                        onClick={() => moveToStep(Summary.name.key)}
                      >
                        {Summary.name.value ? Summary.name.value : ""}
                      </span>
                      {node.type === "Cruise"
                        ? `your desired ${
                            Summary.cruiseDestinations.value.length > 1
                              ? "destinations are "
                              : "destination is "
                          }`
                        : ", You are going to the"}{" "}
                      {console.log(
                        "amaan",
                        Summary.cruiseDestinations.value.length
                      )}
                      <span
                        className="sumvalue"
                        onClick={() => {
                          node.type === "Cruise"
                            ? moveToStep(Summary.cruiseDestinations.key)
                            : moveToStep(Summary.destination.key);
                        }}
                      >
                        {node.type === "Cruise"
                          ? Summary.cruiseDestinations.value
                            ? Summary.cruiseDestinations.value.map(
                                (node: any, index: any) => {
                                  return (
                                    <span key={index}>
                                      {index !== 0
                                        ? Summary.cruiseDestinations.value
                                            .length ===
                                          index + 1
                                          ? "  and "
                                          : " , "
                                        : ""}
                                      <span
                                        className="sumvalue"
                                        onClick={() =>
                                          moveToStep(
                                            Summary.cruiseDestinations.key
                                          )
                                        }
                                      >
                                        {node}
                                      </span>
                                    </span>
                                  );
                                }
                              )
                            : ""
                          : Summary.destination.value
                          ? Summary.destination.value
                          : ""}
                      </span>{" "}
                      {node.type === "Cruise"
                        ? `. Cruise Line${
                            Summary.preferredCruiseLines.value.length > 1
                              ? "s"
                              : ""
                          }`
                        : "from"}{" "}
                      <span
                        className="sumvalue"
                        onClick={() => {
                          node.type === "Cruise"
                            ? moveToStep(Summary.preferredCruiseLines.key)
                            : moveToStep(Summary.from.key);
                        }}
                      >
                        {node.type === "Cruise"
                          ? Summary.preferredCruiseLines.value
                            ? Summary.preferredCruiseLines.value.map(
                                (node: any, index: any) => {
                                  return (
                                    <span key={index}>
                                      {index !== 0
                                        ? Summary.preferredCruiseLines.value
                                            .length ===
                                          index + 1
                                          ? " and "
                                          : ", "
                                        : ""}
                                      <span
                                        className="sumvalue"
                                        onClick={() =>
                                          moveToStep(
                                            Summary.preferredCruiseLines.key
                                          )
                                        }
                                      >
                                        {node}
                                      </span>
                                    </span>
                                  );
                                }
                              )
                            : ""
                          : Summary.from.value
                          ? Summary.from.value
                          : ""}
                      </span>
                      {node.type === "Cruise"
                        ? " . Departure Port"
                        : ", during the dates from"}{" "}
                      <span
                        className="sumvalue"
                        onClick={() => {
                          node.type === "Cruise"
                            ? moveToStep(Summary.destination.value)
                            : moveToStep(Summary.startEndDate.key);
                        }}
                      >
                        {node.type === "Cruise"
                          ? Summary.destination.value
                            ? Summary.destination.value
                            : " "
                          : moment(
                              Summary.startEndDate.value?.startDate
                            ).format("YYYY-MM-DD")}
                      </span>{" "}
                      {node.type === "Cruise" ? ",month" : "to"}{" "}
                      <span
                        className="sumvalue"
                        onClick={() => {
                          node.type === "Cruise"
                            ? moveToStep(Summary.desiredCuriseMonth.key)
                            : moveToStep(Summary.startEndDate.key);
                        }}
                      >
                        {" "}
                        {node.type === "Cruise"
                          ? Summary.desiredCuriseMonth.value
                            ?date[0]===date[1]?date[0]:Summary.desiredCuriseMonth.value
                             : currentMonth
                          : moment(Summary.startEndDate.value?.endDate).format(
                              "YYYY-MM-DD"
                            )}
                      </span>{" "}
                      {node.type === "Cruise" && ",length"}
                      <span
                        className="sumvalue"
                        onClick={() => {
                          node.type === "Cruise" &&
                            moveToStep(Summary.desiredCruiseLength.key);
                        }}
                      >
                        {" "}
                        {node.type === "Cruise" &&
                        Summary.desiredCruiseLength.value
                          ? Summary.desiredCruiseLength.value
                          : ""}
                      </span>
                      . You are going to travel in a group of &nbsp;
                      <span
                        className="sumvalue"
                        onClick={() => moveToStep("tripPassengers")}
                      >
                        {childrensCount + adultsCount}
                      </span>
                      &nbsp;people:&nbsp;
                      <span
                        className="sumvalue"
                        onClick={() => moveToStep("tripPassengers")}
                      >
                        {adultsCount}
                      </span>
                      &nbsp;adult{adultsCount > 1 ? "s" : ""}, &nbsp;
                      <span
                        className="sumvalue"
                        onClick={() => moveToStep("tripPassengers")}
                      >
                        {childrensCount}
                      </span>
                      &nbsp;{childrensCount > 1 ? "children" : "child"}
                      {childrensCount > 0 ? (
                        <>
                          {" "}
                          of age{" "}
                          <span
                            className="sumvalue"
                            onClick={() => moveToStep("tripPassengers")}
                          >
                            {" "}
                            {roomArraangement.map((node: any, index: any) => {
                              return (
                                <span key={index}>
                                  {node.childOneAge >= 0
                                    ? index !== 0
                                      ? ", " + node.childOneAge
                                      : "" + node.childOneAge
                                    : ""}
                                  {node.childTwoAge >= 0
                                    ? ", " + node.childTwoAge
                                    : ""}
                                  {node.childThreeAge >= 0
                                    ? ", " + node.childThreeAge
                                    : ""}
                                  {node.childFourAge >= 0
                                    ? ", " + node.childFourAge
                                    : ""}
                                </span>
                              );
                            })}
                          </span>
                          <span>
                            &nbsp; year{childrensCount > 1 ? "s" : ""}
                          </span>
                        </>
                      ) : null}
                      . You need {roomArraangement.length}&nbsp;
                      {node.type === "Cruise" && (
                        <span
                          onClick={() =>
                            moveToStep(Summary.roomPreference.value)
                          }
                        >
                          {Summary.roomPreference.value
                            ? Summary.roomPreference.value.map(
                                (node: any, index: any) => {
                                  return (
                                    <span key={index}>
                                      {index !== 0
                                        ? Summary.roomPreference.value
                                            .length ===
                                          index + 1
                                          ? " and "
                                          : ", "
                                        : ""}
                                      <span
                                        className="sumvalue"
                                        onClick={() =>
                                          moveToStep(Summary.roomPreference.key)
                                        }
                                      >
                                        {node}
                                      </span>
                                    </span>
                                  );
                                }
                              )
                            : ""}
                        </span>
                      )}
                      &nbsp;{node.type === "Cruise" ? "cabin" : "room"}
                      {roomArraangement.length > 1 ? "s" : ""}:
                      {roomArraangement.map((age: any, key: any) => {
                        return (
                          <span className="summery">
                            &nbsp;({key + 1}) for{" "}
                            <span
                              className="sumvalue"
                              onClick={() => moveToStep("tripPassengers")}
                            >
                              {" "}
                              &nbsp;{age.adults}{" "}
                            </span>
                            &nbsp;adult{age.adults > 1 ? "s" : ""},{" "}
                            <span
                              className="sumvalue"
                              onClick={() => moveToStep("tripPassengers")}
                            >
                              &nbsp; {age.childrens}{" "}
                            </span>{" "}
                            &nbsp;kid{age.childrens > 1 ? "s" : ""}{" "}
                            {age.childrens > 0 ? (
                              <span className="summery">age &nbsp;</span>
                            ) : null}{" "}
                            <span>
                              {age.childOneAge > -1 ? (
                                <span
                                  className="sumvalue"
                                  onClick={() => moveToStep("tripPassengers")}
                                >
                                  {" "}
                                  {age.childOneAge}{" "}
                                </span>
                              ) : null}
                              {/* &nbsp;{" "} */}
                              {age.childTwoAge > -1 ? (
                                <span
                                  className="sumvalue"
                                  onClick={() => moveToStep("tripPassengers")}
                                >
                                  {" "}
                                  {"," + age.childTwoAge}{" "}
                                </span>
                              ) : null}
                              {/* &nbsp;{" "} */}
                              {age.childThreeAge > -1 ? (
                                <span
                                  className="sumvalue"
                                  onClick={() => moveToStep("tripPassengers")}
                                >
                                  {" "}
                                  {"," + age.childThreeAge}{" "}
                                </span>
                              ) : null}
                              {/* &nbsp;{" "} */}
                              {node.type === "Cruise" &&
                              age.childFourAge > -1 ? (
                                <span
                                  className="sumvalue"
                                  onClick={() => moveToStep("tripPassengers")}
                                >
                                  {" "}
                                  {"," + age.childFourAge}{" "}
                                </span>
                              ) : null}
                            </span>
                            .{" "}
                          </span>
                        );
                      })}
                      Your total trip budget is up to{" "}
                      <span
                        className="sumvalue"
                        onClick={() => moveToStep(Summary.budget.key)}
                      >
                        {Summary.budget.value ? Summary.budget.value : ""}
                      </span>
                      .
                    </div>
                    <br />
                    <div>
                      {node.type !== "Cruise" && "You are going on a"} &nbsp;
                      <span
                        className="sumvalue"
                        onClick={() => moveToStep(Summary.occasion.key)}
                      >
                        {node.type !== "Cruise" && Summary.occasion.value
                          ? Summary.occasion.value.length === 1 &&
                            Summary.occasion.value[0] === "Other"
                            ? "Vacation"
                            : Summary.occasion.value
                                .filter((n: any) => {
                                  return n !== "Other";
                                })
                                .map((node: any, index: any) => {
                                  return (
                                    <span key={index}>
                                      {index !== 0
                                        ? Summary.occasion.value.filter(
                                            (n: any) => {
                                              return n !== "Other";
                                            }
                                          ).length ===
                                          index + 1
                                          ? " and "
                                          : ", "
                                        : ""}
                                      <span
                                        className="sumvalue"
                                        onClick={() =>
                                          moveToStep(Summary.occasion.key)
                                        }
                                      >
                                        {node}
                                      </span>
                                    </span>
                                  );
                                })
                          : ""}
                      </span>{" "}
                      {node.type !== "Cruise" && "to enjoy"} &nbsp;
                      {node.type !== "Cruise" &&
                      Summary.accomodationPreferences.value
                        ? Summary.accomodationPreferences.value.map(
                            (node: any, index: any) => {
                              return (
                                <span key={index}>
                                  {index !== 0
                                    ? Summary.accomodationPreferences.value
                                        .length ===
                                      index + 1
                                      ? " and "
                                      : ", "
                                    : ""}
                                  <span
                                    className="sumvalue"
                                    onClick={() =>
                                      moveToStep(
                                        Summary.accomodationPreferences.key
                                      )
                                    }
                                  >
                                    {node}
                                  </span>
                                </span>
                              );
                            }
                          )
                        : ""}
                      {node.type !== "Cruise" && ". You prefer"} &nbsp;
                      {node.type !== "Cruise" && Summary.stayRequirements.value
                        ? Summary.stayRequirements.value.map(
                            (node: any, index: any) => {
                              return (
                                <span key={index}>
                                  {index !== 0
                                    ? Summary.stayRequirements.value.length ===
                                      index + 1
                                      ? " and "
                                      : ", "
                                    : ""}
                                  <span
                                    className="sumvalue"
                                    onClick={() =>
                                      moveToStep(Summary.stayRequirements.key)
                                    }
                                  >
                                    {node}
                                  </span>
                                </span>
                              );
                            }
                          )
                        : ""}
                      {node.type !== "Cruise" && ". You want to have"} &nbsp;{" "}
                      {node.type !== "Cruise" && Summary.interests.value
                        ? Summary.interests.value.map(
                            (node: any, index: any) => {
                              return (
                                <span key={index}>
                                  {index !== 0
                                    ? Summary.interests.value.length ===
                                      index + 1
                                      ? " and "
                                      : ", "
                                    : ""}
                                  <span
                                    className="sumvalue"
                                    onClick={() =>
                                      moveToStep(Summary.interests.key)
                                    }
                                  >
                                    {node}
                                  </span>
                                </span>
                              );
                            }
                          )
                        : ""}
                      .<br />
                      <br />
                      <div>
                        Your email id is{" "}
                        <span
                          className="sumvalue"
                          onClick={() => moveToStep(Summary.email.key)}
                        >
                          {Summary.email.value}
                        </span>{" "}
                        and your Phone Number is{" "}
                        <span
                          className="sumvalue"
                          onClick={() => moveToStep(Summary.email.key)}
                        >
                          {Summary.phone.value}
                        </span>
                      </div>
                      {console.log(
                        "aro value",
                        Summary.cruiseDestinations.value
                      )}
                      <div className="infoMsg">
                        You are almost there! Your request has not been saved
                        yet.
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="tripLocation">
                    {Summary.destination.value
                      ? "Your Trip Profile to " + Summary.destination.value
                      : ""}
                  </div>
                  <h4 className="h3Header">
                    {node.id === "0x25892b5c5"
                      ? node?.question.replace(/\?/g, "")
                      : node?.question}
                    &nbsp;
                    {node.id === "0x25892b4e1"
                      ? airSelectedStatus
                        ? " (including flights)"
                        : " (excluding flights)"
                      : node.id === "0x25892b5c5"
                      ? Summary.destination.value + " ?"
                      : null}
                    {!node.skippable ? (
                      <span style={{ color: "#273443" }}> *</span>
                    ) : null}
                    {/* *{node.id}-{node.key}* */}
                    {/* **{node?.hide === 1 ? "true" : "false"}** */}
                  </h4>
                  {node?.questionInfo ? (
                    <div className="Explanation">{node?.questionInfo}</div>
                  ) : null}
                  <div className="maxArea">
                    {node?.answerType === "TextInput" ||
                    node.answerType === "Email" ||
                    node.answerType === "Phone" ? (
                      <div className="textBoxArea">
                        <TextField
                          value={node.selectedAns}
                          onChange={(event) =>
                            handleAnsSelection(event.target.value, node, {})
                          }
                          id="outlined-basic"
                          label={node.placeHolder}
                          size="small"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </div>
                    ) : node?.answerType === "FirstNameLastName" ? (
                      <div className="textBoxArea">
                        <div className="marginRight">
                          {/* {node.selectedAns1} + {node.selectedAns2} = {node.selectedAns} */}
                          <TextField
                            value={node.selectedAns1}
                            onChange={(event) =>
                              handleAnsSelection(
                                event.target.value,
                                node,
                                {},
                                "first"
                              )
                            }
                            id="outlined-basic"
                            label="First name"
                            size="small"
                            fullWidth={true}
                            variant="outlined"
                          />
                        </div>
                        <div className="marginRight">
                          <TextField
                            value={node.selectedAns2}
                            onChange={(event) =>
                              handleAnsSelection(
                                event.target.value,
                                node,
                                {},
                                "last"
                              )
                            }
                            id="outlined-basic"
                            label="Last name"
                            size="small"
                            fullWidth={true}
                            variant="outlined"
                          />
                        </div>
                      </div>
                    ) : node?.answerType === "TextAreaInput" ? (
                      <div className="textMBoxArea">
                        <TextField
                          multiline
                          minRows={4}
                          maxRows={4}
                          value={node.selectedAns}
                          onChange={(event) =>
                            handleAnsSelection(event.target.value, node, {})
                          }
                          id="outlined-basic"
                          label={node.placeHolder}
                          size="small"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </div>
                    ) : node?.answerType === "MultipleChoice" ||
                      node?.answerType === "SingleChoice" ? (
                      <span>
                        {Object.keys(node.groupedList).map((key, i) => {
                          return (
                            <div
                              className={
                                Object.keys(node.groupedList).length > 1
                                  ? "NewLine"
                                  : ""
                              }
                            >
                              
          
                              {node.groupedList[key]
                                .sort(
                                  (a: any, b: any) => a.sortOrder - b.sortOrder
                                )

                                .map((key: any, n: any) => {
                                  return (
                                    <span
                                      title={key.tooltip}
                                      onClick={() =>
                                        handleAnsSelection(key.value, node, {})
                                      }
                                      key={n}
                                      className={
                                        node.selectedAns
                                          ? node.selectedAns.findIndex(
                                              (n: any) => n === key.value
                                            ) !== -1
                                            ? "options selected"
                                            : "options"
                                          : "options"
                                      }
                                    >
                                      {key.value}
                                    </span>
                                  );
                                })}
                            </div>
                          );
                        })}
                      </span>
                    ) : node?.answerType === "MultipleChoiceCheckBox" ? (
                      <div>
                      <span>
                        {Object.keys(node.groupedList).map((key, i) => {
                          return (
                            <div
                              className={
                                Object.keys(node.groupedList).length > 1
                                  ? "checkbox"
                                  : "checkbox"
                              }
                            >
                              {node.groupedList[key]
                                .sort((a: any, b: any) => {
                                  console.log("asasasa", a);

                                  return (
                                    // a.filter((data:{value:string})=>data.value===node.selectedAns[0])
                                    a.sortOrder - b.sortOrder
                                  );
                                })
                                .map((key: any, n: any) => {
                                  console.log("any", n);

                                  console.log("key", node);
                                  console.log(
                                    "node.selectedAns.includes",
                                    node.selectedAns
                                  );
                                  console.log("adfghj",key.value);
                  
                                 console.log("hihihi",arr)
                                  return (
                                    <div>
                                    <div className="list">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            title={key.tooltip}
                                            key={n}
                                            checked={
                                              node?.selectedAns.includes(
                                                key.value
                                              ) ||
                                              node?.selectedAns.includes(
                                                key.value.toLowerCase()
                                              )
                                            }
                                            className={
                                              node?.selectedAns === key.value
                                                ? "MuiSvgIcon-root PrivateSwitchBase-checked-6"
                                                : "MuiSvgIcon-root"
                                            }
                                            onClick={() =>
                                              handleAnsSelection(
                                                key.value,
                                                node,
                                                {}
                                              )
                                            }
                                            name="gilad"
                                          />
                                        }
                                        label=""
                                      />

                                      <div className="listLabel">
                    
                                        <label> {key.value}</label>
                                      </div>
                                     
                     
                      
                       
               </div>
               
               {node.answerChoices.filter((n:any)=>{
 return  n.value.toLowerCase().includes(Location.toLowerCase());
}).length===0?(key.sortOrder===1) && node.selectedAns.includes('Any Destination')?
              //  (console.log(node.answerChoices.includes(Location)))
                    ( <div className="cruiseDestinationTextBox">
                         <TextField
                          value={node.selectedAns.includes('Any Destination')?node.selectedAns[0]:null}
                          onChange={(e) => {
                            node.selectedAns[0]=e.target.value
                            dispatch(setDesitnationThroughParams(e.target.value))
                                   }}
                          id="outlined-basic"
                          label={node.placeHolder}
                          size="small"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </div>):null:null}
               </div>
               
                                  );
                                })}
                            </div>
                          );
                        })}
                      </span>
                     
                      </div>
                      
                    ) : node?.answerType === "MultipleChoiceTextInput" ? (
                      <div>
                        <div>
                          {Object.keys(node.groupedList).map((key, i) => {
                            return (
                              <div
                                className={
                                  Object.keys(node.groupedList).length > 1
                                    ? "NewLine"
                                    : ""
                                }
                              >
                                {node.groupedList[key]
                                  .sort(
                                    (a: any, b: any) =>
                                      a.sortOrder - b.sortOrder
                                  )
                                  .map((key: any, n: any) => {
                                    return (
                                      <span
                                        title={key.tooltip}
                                        onClick={() =>
                                          handleAnsSelection(
                                            key.value,
                                            node,
                                            {}
                                          )
                                        }
                                        key={n}
                                        className={
                                          node.selectedAns
                                            ? node.selectedAns.findIndex(
                                                (n: any) => n === key.value
                                              ) !== -1
                                              ? "options selected"
                                              : "options"
                                            : "options"
                                        }
                                      >
                                        {key.value}
                                      </span>
                                    );
                                  })}
                              </div>
                            );
                          })}
                        </div>
                        <div className="textMBoxArea">
                          <TextField
                            value={node.selectedAns2}
                            onChange={(event) =>
                              handleAnsSelection(event.target.value, node, {})
                            }
                            id="outlined-basic"
                            label={
                              node.invalidResponse
                                ? node.invalidResponse
                                : "Other"
                            }
                            size="small"
                            fullWidth={true}
                            variant="outlined"
                          />
                        </div>
                      </div>
                    ) : node?.answerType === "DateRange" ? (
                      <div style={{ marginTop: "10px", marginBottom: "40px" }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div>
                            <div className="displayfromto">From</div>
                            <div
                              className="displaydate"
                              onClick={() => handleToggleHover(true)}
                            >
                              <div className="date">
                                {moment(selectionRange.startDate).format("DD")}
                                <div className="week">
                                  {moment(selectionRange.startDate).format(
                                    "dddd"
                                  )}

                                  <div className="year">
                                    {moment(selectionRange.startDate).format(
                                      "MMM YYYY"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="displayfromto">To</div>
                            <div
                              className="displaydate"
                              onClick={() => handleToggleHover(true)}
                            >
                              <div className="date">
                                {moment(selectionRange.endDate).format("DD")}
                                <div className="week">
                                  {moment(selectionRange.endDate).format(
                                    "dddd"
                                  )}

                                  <div className="year">
                                    {moment(selectionRange.endDate).format(
                                      "MMM YYYY"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <TextField onFocus={() => handleToggleHover(true)} id="outlined-basic" size="small" fullWidth={false} variant="outlined" />
                                                        <TextField style={{ marginLeft: "8px" }} value={Moment(selectionRange.endDate).format('MMMM, DD MMM YYYY')} onFocus={() => handleToggleHover(true)} id="outlined-basic" size="small" fullWidth={false} variant="outlined" /> */}
                        {toggleHover ? (
                          <div className="PopUpElement">
                            <div
                              onClick={() => handleToggleHover(false)}
                              className="popUpBG"
                            ></div>
                            <div className="PopUpElementComp">
                              <DateRange
                                minDate={endDate}
                                ranges={[selectionRange]}
                                onChange={handleSelect}
                                showDateDisplay={true}
                                months={2}
                                showMonthAndYearPickers={false}
                                direction="horizontal"
                              />
                              <div style={{ height: "51px" }}>
                                <span
                                  onClick={() => handleToggleHover(false)}
                                  className="NextButton okButton"
                                >
                                  Apply
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div
                          style={{ marginTop: "40px" }}
                          className="datesflex"
                        >
                          <div className="total">
                            Total {totalDaysSelected} Days selected
                          </div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={dateFlexFlag}
                                onChange={() => setDateFlexFlag(!dateFlexFlag)}
                                name="gilad"
                              />
                            }
                            label=""
                          />
                          My dates are flexible
                          {dateFlexFlag ? (
                            <div className="passeng">
                              <div
                                className="actButton"
                                onClick={() => handleFlexibleDate(-1, node)}
                              >
                                -
                              </div>
                              <div className="actCount">
                                {flexibleDate} Day{flexibleDate > 1 ? "s" : ""}
                              </div>
                              <div
                                className="actButton"
                                onClick={() => handleFlexibleDate(1, node)}
                              >
                                +
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : node?.answerType === "Passengers" ? (
                      <div className="Passengers">
                        <div className="passeng">
                          {/* <div className="actButton" onClick={() => handleAdults(-1, node)}>-</div> */}
                          <div className="actCountName">
                            {" "}
                            Adult{adultsCount > 1 ? "s" : ""}
                          </div>
                          <div className="actCountadults">{adultsCount} </div>
                          <div className="actCountName">
                            Children{childrensCount > 1 ? "s" : ""}
                          </div>
                          <div className="actCountadults">
                            {childrensCount}{" "}
                          </div>
                          {/* <div className={roomArraangement.length * 4 > (adultsCount + childrensCount) ? "actButton" : "actButton disabled"} onClick={() => roomArraangement.length * 4 > (adultsCount + childrensCount) ? handleAdults(1, node) : null}>+</div> */}
                        </div>
                        <div className="passeng">
                          {/* <div className="actButton" onClick={() => handleChildrens(-1, node)}>-</div> */}
                          {/* <div className="actCount">{childrensCount} Children{childrensCount > 1 ? 's' : ''}</div> */}
                          {/* <div className={(roomArraangement.length * 2 > childrensCount) && (roomArraangement.length * 4 > (adultsCount + childrensCount)) ? "actButton" : "actButton disabled"} onClick={() => (roomArraangement.length * 2 > childrensCount) && (roomArraangement.length * 4 > (adultsCount + childrensCount)) ? handleChildrens(1, node) : null}>+</div> */}
                        </div>
                        <div className="RoomArrangement">
                          <h4 className="flex">
                            <span className="room">
                              {node?.type === "Cruise"
                                ? "Cabin Arrangement"
                                : "Room Arrangement"}
                            </span>
                            {/* <span onClick={() => addNewRoom(node)} className="PlusIconRight">
                                                                                <div>+&nbsp;</div>
                                                                                <span>Add</span>
                                                                            </span> */}
                          </h4>
                          <div className="flexRooms">
                            {roomArraangement.map((nd: any, index: number) => {
                              console.log("aro", nd);

                              return (
                                <>
                                  <div className="rooms">
                                    <div className="roomContainer">
                                      <div className="roomHeader">
                                        <span className="roomsTitle">
                                          {/* <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><rect fill="none" height="24" width="24" /><path d="M7,21H3V8l13-5v7H7V21z M19,10c-1.1,0-2,0.9-2,2H9v9h5v-5h2v5h5v-9C21,10.9,20.1,10,19,10z" /></svg> */}

                                          <div>
                                            {node?.type === "Cruise"
                                              ? "Cabin"
                                              : "Room"}
                                          </div>
                                        </span>
                                        {index !== 0 ? (
                                          <span
                                            onClick={() => deleteRoom(nd, node)}
                                            className="roomsRemove"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              width="24"
                                            >
                                              <path
                                                d="M0 0h24v24H0V0z"
                                                fill="none"
                                              />
                                              <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z" />
                                            </svg>
                                          </span>
                                        ) : null}
                                      </div>
                                      <div className="flex roomAction">
                                        <div className="nme">
                                          Adults
                                          <div className="age">Aged 13 +</div>
                                        </div>
                                        <div className="actionBox">
                                          <div
                                            className={
                                              nd.adults <= 1
                                                ? "roomBtn disabled"
                                                : "roomBtn"
                                            }
                                            onClick={() =>
                                              updateCount(
                                                -1,
                                                index,
                                                "adult",
                                                node
                                              )
                                            }
                                          >
                                            <div className="sub">-</div>
                                          </div>
                                          <div className="roomCount">
                                            <div className="count">
                                              {nd.adults}
                                            </div>
                                          </div>
                                          <div
                                            className={
                                              (
                                                node.type === "Cruise"
                                                  ? nd.adults + nd.childrens >=
                                                    8
                                                  : nd.adults + nd.childrens >=
                                                    5
                                              )
                                                ? "roomBtn disabled"
                                                : "roomBtn"
                                            }
                                            onClick={() =>
                                              updateCount(
                                                1,
                                                index,
                                                "adult",
                                                node
                                              )
                                            }
                                          >
                                            <div className="sub">+</div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex roomAction">
                                        <div className="nme">Children</div>
                                        <div className="actionBox">
                                          <div
                                            className={
                                              nd.childrens <= 0
                                                ? "roomBtn disabled"
                                                : "roomBtn"
                                            }
                                            onClick={() =>
                                              updateCount(
                                                -1,
                                                index,
                                                "children",
                                                node
                                              )
                                            }
                                          >
                                            <div className="sub">-</div>
                                          </div>
                                          <div className="roomCount">
                                            <div className="count">
                                              {nd.childrens}
                                            </div>
                                          </div>
                                          <div
                                            className={
                                              (
                                                node.type === "Cruise"
                                                  ? nd.adults + nd.childrens >=
                                                      8 || nd.childrens >= 4
                                                  : nd.adults + nd.childrens >=
                                                      5 || nd.childrens >= 3
                                              )
                                                ? "roomBtn disabled"
                                                : "roomBtn"
                                            }
                                            onClick={() =>
                                              updateCount(
                                                1,
                                                index,
                                                "children",
                                                node
                                              )
                                            }
                                          >
                                            <div className="sub">+</div>
                                          </div>
                                        </div>
                                      </div>
                                      {nd.childrens === 1 ||
                                      nd.childrens === 2 ||
                                      nd.childrens === 3 ||
                                      nd.childrens === 4 ? (
                                        <div className="ChildrenAgeSet">
                                          <div className="ChildAge">
                                            Child 1 age
                                          </div>
                                          <input
                                            className="selectage"
                                            placeholder="Type age here"
                                            value={
                                              nd.childOneAge >= 0
                                                ? nd.childOneAge
                                                : null
                                            }
                                            onChange={(event) =>
                                              handleChildrenChange(
                                                event.target.value,
                                                nd,
                                                1
                                              )
                                            }
                                          />
                                          {/* <select className="selectage" onChange={(event) => handleChildrenChange(event.target.value, nd, 1)} value={nd.childOneAge}>
                                                                                                    {ageGroup.map((age: any) => {
                                                                                                        return <option key={age} value={age}>{age}</option>;
                                                                                                    })}
                                                                                                </select> */}
                                        </div>
                                      ) : null}
                                      {nd.childrens === 2 ||
                                      nd.childrens === 3 ||
                                      nd.childrens === 4 ? (
                                        <div className="ChildrenAgeSet">
                                          <div className="ChildAge">
                                            Child 2 age
                                          </div>
                                          {/* <select className="selectage" onChange={(event) => handleChildrenChange(event.target.value, nd, 2)} value={nd.childTwoAge}>
                                                                                                    {ageGroup.map((age: any) => {
                                                                                                        return <option key={age} value={age}>{age}</option>;
                                                                                                    })}
                                                                                                </select> */}
                                          <input
                                            className="selectage"
                                            placeholder="Type age here"
                                            value={
                                              nd.childTwoAge >= 0
                                                ? nd.childTwoAge
                                                : null
                                            }
                                            onChange={(event) =>
                                              handleChildrenChange(
                                                event.target.value,
                                                nd,
                                                2
                                              )
                                            }
                                          />
                                        </div>
                                      ) : null}
                                      {nd.childrens === 3 ||
                                      nd.childrens === 4 ? (
                                        <div className="ChildrenAgeSet">
                                          <div className="ChildAge">
                                            Child 3 age
                                          </div>
                                          {/* <select className="selectage" onChange={(event) => handleChildrenChange(event.target.value, nd, 3)} value={nd.childThreeAge}>
                                                                                                    {ageGroup.map((age: any) => {
                                                                                                        return <option key={age} value={age}>{age}</option>;
                                                                                                    })}
                                                                                                </select> */}
                                          <input
                                            className="selectage"
                                            placeholder="Type age here"
                                            value={
                                              nd.childThreeAge >= 0
                                                ? nd.childThreeAge
                                                : null
                                            }
                                            onChange={(event) =>
                                              handleChildrenChange(
                                                event.target.value,
                                                nd,
                                                3
                                              )
                                            }
                                          />
                                        </div>
                                      ) : null}
                                      {nd.childrens === 4 ? (
                                        <div className="ChildrenAgeSet">
                                          <div className="ChildAge">
                                            Child 4 age
                                          </div>
                                          {/* <select className="selectage" onChange={(event) => handleChildrenChange(event.target.value, nd, 3)} value={nd.childThreeAge}>
                                                                                                    {ageGroup.map((age: any) => {
                                                                                                        return <option key={age} value={age}>{age}</option>;
                                                                                                    })}
                                                                                                </select> */}
                                          <input
                                            className="selectage"
                                            placeholder="Type age here"
                                            value={
                                              nd.childFourAge >= 0
                                                ? nd.childFourAge
                                                : null
                                            }
                                            onChange={(event) =>
                                              handleChildrenChange(
                                                event.target.value,
                                                nd,
                                                4
                                              )
                                            }
                                          />
                                        </div>
                                      ) : null}
                                      {node.type !== "Cruise" &&
                                      nd.adults + nd.childrens >= 3 ? (
                                        <div className="roomInfo">
                                          Not all hotels offer rooms which
                                          accommodate 3 or more guests. Please
                                          consider adding more rooms to see more
                                          hotel options and receive better
                                          prices
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div
                                    onClick={() => addNewRoom(node)}
                                    className="PlusIconRight"
                                  >
                                    <div>+&nbsp;</div>
                                    <span>
                                      {node.type === "Cruise"
                                        ? "Add a cabin"
                                        : "Add a room"}
                                    </span>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    ) : node?.answerType === "Toggler" ? (
                      <div className="passeng">
                        <div
                          className="actButton"
                          onClick={() => handleRooms(-1, node)}
                        >
                          -
                        </div>
                        <div className="actCount">
                          {rooms} Room{rooms > 1 ? "s" : ""}
                        </div>
                        <div
                          className="actButton"
                          onClick={() => handleRooms(1, node)}
                        >
                          +
                        </div>
                      </div>
                    ) : null}
                    {node?.subQueList
                      ? node?.subQueList.map((subNode: any, kk: any) => {
                          return (
                            <div>
                              <h4 className="SubTitle">
                                {subNode?.question}{" "}
                                {!subNode.skippable ? (
                                  <span style={{ color: "#273443" }}> *</span>
                                ) : null}
                              </h4>
                              {/* *{subNode.id}-{subNode.key}* */}
                              {subNode?.questionInfo ? (
                                <div className="Explanation">
                                  {subNode?.questionInfo}{" "}
                                </div>
                              ) : null}
                              {subNode.answerType === "Phone" ? (
                                <div className="textBoxArea">
                                  <PhoneInput
                                    country={"us"}
                                    inputProps={{
                                      name: "phone",
                                      required: true,
                                    }}
                                    value={subNode.selectedAns}
                                    // onChange={(phone) => console.log("testinphone", phone)}
                                    onChange={(phone) =>
                                      handleAnsSelection(phone, subNode, node)
                                    }
                                  />
                                  {/* <TextField value={subNode.selectedAns} onChange={(event) => handleAnsSelection(event.target.value, subNode, node)} id="outlined-basic" label={subNode.invalidResponse ? subNode.invalidResponse : 'Please write your Answer'} size="small" fullWidth={true} variant="outlined" /> */}
                                </div>
                              ) : subNode?.answerType === "MultipleChoice" ||
                                subNode?.answerType === "SingleChoice" ? (
                                subNode.answerChoices.map(
                                  (key: any, n: any) => {
                                    return (
                                      <span
                                        onClick={() =>
                                          handleAnsSelection(
                                            key.value,
                                            subNode,
                                            node
                                          )
                                        }
                                        key={n}
                                        className={
                                          subNode.selectedAns
                                            ? subNode.selectedAns.findIndex(
                                                (n: any) => n === key.value
                                              ) !== -1
                                              ? "options selected"
                                              : "options"
                                            : "options"
                                        }
                                      >
                                        {key.value}
                                      </span>
                                    );
                                  }
                                )
                              ) : subNode?.answerType === "TextAreaInput" ? (
                                <div className="textMBoxArea">
                                  <TextField
                                    multiline
                                    minRows={4}
                                    maxRows={4}
                                    value={subNode.selectedAns}
                                    onChange={(event) =>
                                      handleAnsSelection(
                                        event.target.value,
                                        subNode,
                                        node
                                      )
                                    }
                                    id="outlined-basic"
                                    label={subNode.placeHolder}
                                    size="small"
                                    fullWidth={true}
                                    variant="outlined"
                                  />
                                </div>
                              ) : subNode?.answerType === "DateRange" ? (
                                //         <div style={{ marginTop: "10px", marginBottom: "40px",display:"flex" }}>

                                //         <div>
                                //     {/* <div className="displayfromto"></div> */}
                                //     <div
                                //       className="displaydate"
                                //       onClick={() => handleToggleHover(true)}
                                //     >
                                //           <div className="yearNew">
                                //             <div className="calendar">
                                //             <CalendarMonthOutlinedIcon/>

                                //          </div>
                                //             {subNode.selectedAns?subNode.selectedAns:month}
                                //           </div>

                                //     </div>
                                //   </div>
                                //   {toggleHover ? (
                                //   <div className="PopUpElement">
                                //     <div
                                //       onClick={() => handleToggleHover(false)}
                                //       className="popUpBG"
                                //     ></div>
                                //     <div className="PopUpElementComp">
                                //       <div className="closeDisplay">
                                //         <div className="closeDisplayIn"
                                //          onClick={()=>handleToggleHover(false)} ><CloseIcon/></div></div>
                                //          <div className="showMonth">
                                //      <DateMonthPicker setMonthYear={setMonth}
                                //      />
                                //      </div>

                                //       <div style={{ height: "51px" }}>
                                //         <span
                                //           onClick={() => {
                                //             handleAnsSelection(
                                //               month,
                                //               subNode,
                                //               node
                                //             )

                                //             handleToggleHover(false)
                                //           }}
                                //           className="NextButton month-ok-button"
                                //         >
                                //           Apply
                                //         </span>
                                //       </div>
                                //     </div>
                                //   </div>
                                // ) : null}
                                //   </div>
                                
                                  <MonthRangePicker
                                    setFinal={setMonth}
                                    callBack={handleAnsSelection}
                                    subNode={subNode}
                                    node={node}
                                    monthRange={monthRange}
                                    setMonthRange={setmonthRange}
                                    maxDate={maxDate}
                                  />
                                
                              ) : subNode?.answerType === "Passengers" ? (
                                <div className="Passengers">
                                  <div className="passeng">
                                    <div
                                      className="actButton"
                                      onClick={() => handleAdults(-1, subNode)}
                                    >
                                      -
                                    </div>
                                    <div className="actCount">
                                      {adultsCount} Adult
                                      {adultsCount > 1 ? "s" : ""}
                                    </div>
                                    <div
                                      className="actButton"
                                      onClick={() => handleAdults(1, subNode)}
                                    >
                                      +
                                    </div>
                                  </div>
                                  <div className="passeng">
                                    <div
                                      className="actButton"
                                      onClick={() =>
                                        handleChildrens(-1, subNode)
                                      }
                                    >
                                      -
                                    </div>
                                    <div className="actCount">
                                      {childrensCount} Children
                                      {childrensCount > 1 ? "s" : ""}
                                    </div>
                                    <div
                                      className="actButton"
                                      onClick={() =>
                                        handleChildrens(1, subNode)
                                      }
                                    >
                                      +
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          );
                        })
                      : //Loop End
                        null}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          node?.validResponse
            ? "moveToNext animationFadeIn2"
            : "moveToNext animationFadeIn1"
        }
      >
        <div className="errorValidation">{validation}</div>
        {/* <div className="selectedAns">
            {
                node.selectedAns ?
                    node?.answerType === 'MultipleChoice' ?
                        node.selectedAns.map((S: any, index: number) => {
                            return <span key={index}>
                                {index > 0 ? '; ' : null}
                                {S}
                            </span>
                        })
                        : node?.answerType === 'TextInput' ?
                            node.selectedAns
                            : null
                    : null
            }
        </div> */}
        {index !== 1 ? (
          <span onClick={() => moveToBack(index)} className="BackButton">
            Back
          </span>
        ) : null}
        {node.skippable &&
        (!node.selectedAns ||
          node.selectedAns === null ||
          node?.selectedAns.length === 0) &&
        !showSummery ? (
          <span onClick={() => setAnsSelection(node)}className="NextButton">
            {node.selectedAns2 &&
            node.selectedAns2 !== "" &&
            node.selectedAns !== ""
              ? "Next"
              : "Skip"}
          </span>
        ) : null}
        {!showSummery &&
        !(
          node.skippable &&
          (!node.selectedAns ||
            node.selectedAns === null ||
            node.selectedAns?.length === 0)
        ) ? (
          // !node.status ?
          <span onClick={() => setAnsSelection(node)} className="NextButton">
            Next
          </span>
        ) : // :
        // <span className="NextButton">Change my answer</span>
        null}
        {showSummery ? (
          // !node.status ?
          <span
            onClick={() => submitData()}
            className={!submitAPIFlag ? "NextButton" : "notAllowed"}
          >
            {submitAPIFlag ? <div className="inlineloader"></div> : null}
            {submitAPIFlag ? "Submitting" : "Submit"}
          </span>
        ) : // :
        // <span className="NextButton">Change my answer</span>
        null}
      </div>
    </>
  );
};
export default NestedQue;
